<template>
  <!-- Start FAQ  -->
  <div class="faq">
    <div class="container">
      <div class="main-title text-center">
        <span class="separator">
          <i class="flaticon-chakra"></i>
        </span>
        <h2>FAQ</h2>
      </div>
      <div class="row">
        <div class="col-12">
          <div id="accordion" class="accordion" role="tablist">
            <!------------ item ------------>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <h5>
                  <b-button
                    block
                    v-b-toggle.accordion-1
                    variant="info"
                    class="btn-link"
                  >
                    Do I need to bring anything with me?
                  </b-button>
                </h5>
              </b-card-header>
              <b-collapse
                id="accordion-1"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    >You can bring your own mat or use the ones provided.
                  </b-card-text>
                 
                </b-card-body>
              </b-collapse>
            </b-card>
            <!------------ item ------------>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <h5>
                  <b-button
                    block
                    v-b-toggle.accordion-2
                    variant="info"
                    class="btn-link"
                    >What kind of clothes should I wear while doing Yoga?
                  </b-button>
                </h5>
              </b-card-header>
              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    >Wear loose comfortable clothes that don't restrict your movements. T - shirt and leggings will be perfectly fine. 
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!------------ item ------------>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <h5>
                  <b-button
                    block
                    v-b-toggle.accordion-3
                    variant="info"
                    class="btn-link"
                    >How much does it cost?</b-button
                  >
                </h5>
              </b-card-header>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    >Your very first class is free. Then it's £3.50 per class for the first month. 
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!------------ item ------------>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <h5>
                  <b-button
                    block
                    v-b-toggle.accordion-4
                    variant="info"
                    class="btn-link"
                    >How do I join the classes?</b-button
                  >
                </h5>
              </b-card-header>
              <b-collapse
                id="accordion-4"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    >Just get in touch with me via either facebook, e-mail or whatsapp.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <!------------ item
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <h5>
                  <b-button
                    block
                    v-b-toggle.accordion-5
                    variant="info"
                    class="btn-link"
                    >What will I learn in my Extension Classes?
                  </b-button>
                </h5>
              </b-card-header>
              <b-collapse
                id="accordion-5"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text
                    >Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed eiusm tmpor incididunt ut labore et dolore magna aliqua.
                    enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card> ------------>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End FAQ  -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>