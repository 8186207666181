<template>
  <div class="wrraper">
    <app-bar-top></app-bar-top>
    <app-header-inner></app-header-inner>
    <app-page-Title
      :heading="heading"
      :sousHeading="sousHeading"
    ></app-page-Title>
    <app-faq-component> </app-faq-component>
    <app-newsLetter></app-newsLetter>
    <app-footer> </app-footer>
  </div>
</template>

<script>
import UpperBar from "../../components/upperBar.vue";
import headerInner from "../../components/headerInner";
import pageTitle from "../../components/widget/pageTitle.vue";
import faqComponent from "../../components/faq.vue";
import footer from "../../components/footer.vue";
export default {
  data: function () {
    return {
      heading: "FAQs",
      sousHeading: "FAQs",
    };
  },
  components: {
    "app-bar-top": UpperBar,
    "app-header-inner": headerInner,
    "app-page-Title": pageTitle,
    "app-faq-component": faqComponent,
    "app-footer": footer,
  },
};
</script>

<style lang="scss" scoped></style>